import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
} from '@angular/core';
import { EFormShipFromValue } from 'src/app/shared/form-ship-from/form-ship-from.enum';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import cookie from 'cookie';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { RoutingService } from 'src/app/services/routing.service';
import { EGiftOrderType } from '@studiobuki/shared/dist/gift/enums';
import type { TBookAlias } from '@studiobuki/shared/dist/book/interfaces';
import {
  TBookCover,
  TBookWrapping,
} from '@studiobuki/shared/dist/book/interfaces';
import { IShipping } from '@studiobuki/shared/dist/data/shipping/types';
import type { IEmail } from '@studiobuki/web-core/lib/form-email';
import type { TFormPaymentData } from '@studiobuki/web-core/lib/form-payment';
import { DialogsService } from '@studiobuki/web-core/lib/dialogs';
import { ELoaderType, LoaderService } from '@studiobuki/web-core/lib/loader';
import { FirebaseService } from '@studiobuki/web-core/lib/firebase';
import { StripeService } from '@studiobuki/web-core/lib/stripe';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import {
  checkCreatedGiftOrder,
  checkGeneratedGiftOrder,
  checkGiftOrderError,
} from '@studiobuki/shared/dist/gift/functions/checks';
import { Logger } from '@studiobuki/shared/dist/logger';
import type { IAddress } from '@studiobuki/shared/dist/interfaces';
import { EFormPaymentType } from '@studiobuki/shared/dist/interfaces';
import { getPaymentInformation, scrollToSelector } from 'src/utils';
import {
  deleteUndefinedFromObject,
  getError,
  getFullName,
} from '@studiobuki/shared/dist/utils';
import type {
  IGiftOrderCreateParamsCommon,
  TGiftOrderCreated,
  TGiftOrderCreateParams,
  TGiftOrderGenerated,
} from '@studiobuki/shared/dist/gift/interfaces';
import Subscriber from '@studiobuki/shared/dist/subscriber';
import { DB_GIFT_PAYMENTS } from '@studiobuki/shared/dist/constants';
import { sendConversionEventByGiftOrder } from 'src/analytics';
import type { IFormGiftSpecialData } from '../form-gift-special/form-gift-special.interfaces';

const log = new Logger('SectionGiftCheckoutComponent');

@Component({
  selector: 'app-section-gift-checkout[alias][cover][wrapping]',
  templateUrl: './section-gift-checkout.component.html',
  styleUrls: ['./section-gift-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftCheckoutComponent implements OnChanges {
  @Input() type: EGiftOrderType = EGiftOrderType.standart;

  @Input() alias?: TBookAlias | undefined;

  @Input() cover!: TBookCover;

  @Input() wrapping!: TBookWrapping;

  @Input() shipping!: IShipping;

  /** used to touch forms to make errors appear */
  public readonly touchEventEmitter = new EventEmitter<void>();

  public readonly formGiftSpecialData$ = new BehaviorSubject<
    IFormGiftSpecialData | undefined
  >(undefined);

  public readonly formEmailData$ = new BehaviorSubject<IEmail | undefined>(
    undefined,
  );

  public readonly formBillingAddressData$ = new BehaviorSubject<
    IAddress | undefined
  >(undefined);

  private readonly _shipFrom$ = new BehaviorSubject<EFormShipFromValue>(
    EFormShipFromValue.buki,
  );

  public readonly formPaymentData$ = new BehaviorSubject<
    TFormPaymentData | undefined
  >(undefined);

  public readonly submitButtonDisabled$ = new BehaviorSubject<boolean>(true);

  public readonly environment = environment;

  public readonly EGiftOrderType = EGiftOrderType;

  constructor(
    private _dialogsService: DialogsService,
    private _loaderService: LoaderService,
    private _firebaseService: FirebaseService,
    private _stripeService: StripeService,
    private _routingService: RoutingService,
    private _discountService: DiscountService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!changes['alias']?.firstChange) {
      this.submitButtonDisabled$.next(false);
    }
  }

  public async onSubmitButtonClick() {
    const {
      formPaymentData$: { value: formPaymentData },
    } = this;

    const isValid = this._validateForms();

    if (!isValid) return;

    const emit = async () => {
      this._loaderService.show({ type: ELoaderType.payment, data: {} });
      // await this.updateOrderData();
      // await this.pay();

      try {
        const { userGiftOrderId, giftOrder } = await this._submit();

        if (checkGeneratedGiftOrder(giftOrder)) {
          await this._routingService.goToGiftThanks({
            giftId: giftOrder.gifts[0],
            orderId: giftOrder.giftOrderId,
          });
        } else {
          await this._routingService.goToGiftThanks();
        }

        sendConversionEventByGiftOrder(userGiftOrderId, giftOrder);
      } catch (error) {
        log.error(error);
      }

      this._loaderService.hide();
    };

    if (formPaymentData?.type === EFormPaymentType.konbini) {
      const confirmed = await firstValueFrom(
        this._dialogsService.showKonbiniConfirmation().afterClosed(),
      );

      if (!confirmed) return;
    }

    await emit();
  }

  public onFormGiftSpecialDataChange(
    data: IFormGiftSpecialData | undefined,
  ): void {
    this.formGiftSpecialData$.next(data);
    this.submitButtonDisabled$.next(false);
  }

  public onFormEmailDataChange(data: IEmail | undefined): void {
    this.formEmailData$.next(data);
    this.submitButtonDisabled$.next(false);
  }

  public onFormBillingAddressDataChange(data: IAddress | undefined): void {
    this.formBillingAddressData$.next(data);
    this.submitButtonDisabled$.next(false);
  }

  public onShipFromChange(value: EFormShipFromValue): void {
    this._shipFrom$.next(value);
    this.submitButtonDisabled$.next(false);
  }

  public onFormPaymentDataChange(data: TFormPaymentData | undefined): void {
    this.formPaymentData$.next(data);
    this.submitButtonDisabled$.next(false);
  }

  /**
   * filling the customer info form group with test data
   */
  // public fillBillingAddress(data: IFormBillingAddressData): void {
  //   this.onFormBillingAddressDataChange(
  //     Object.assign(this._formBillingAddressData$.value || {}, data),
  //   );
  // }

  /**
   * @returns `true` if form is valid
   */
  private _validateForms(): boolean {
    const {
      alias,
      formGiftSpecialData$: { value: formGiftSpecialData },
      formBillingAddressData$: { value: formAddressBillingData },
      formPaymentData$: { value: formPaymentData },
      type,
    } = this;

    const isAliasInvalid = !alias;
    const isFormGiftSpecialInvalid =
      type === EGiftOrderType.special && !formGiftSpecialData;
    const isAddressBillingDataInvalid = !formAddressBillingData;
    const isFormPaymentDataInvalid =
      type === EGiftOrderType.standart && !formPaymentData;
    /** all forms */
    const isInvalid =
      isAliasInvalid ||
      isFormGiftSpecialInvalid ||
      isAddressBillingDataInvalid ||
      isFormPaymentDataInvalid;

    if (isInvalid) {
      if (isAliasInvalid) {
        scrollToSelector('app-section-gift-books');
      } else if (isFormGiftSpecialInvalid) {
        scrollToSelector('app-form-gift-special');
      } else if (isAddressBillingDataInvalid) {
        scrollToSelector('app-form-customer-info');
      } else if (isFormPaymentDataInvalid) {
        scrollToSelector('app-form-payment');
      }
    }

    this.submitButtonDisabled$.next(isInvalid);
    this.touchEventEmitter.next();

    return !isInvalid;
  }

  private async _submit() {
    const {
      type,
      alias,
      cover,
      wrapping,
      shipping,
      formGiftSpecialData$: { value: giftSpecialData },
      formEmailData$: { value: formEmail },
      formBillingAddressData$: { value: formBillingAddress },
      _shipFrom$: { value: shipFrom },
      formPaymentData$: { value: formPaymentData },
    } = this;

    if (!alias || !formEmail || !formBillingAddress) {
      const err = getError('missing required field', {
        alias,
        formEmail,
        formBillingAddress,
      });
      log.error(err.message);
      throw err;
    }

    const book = {
      alias,
      cover,
      wrapping,
    };

    const discountCampaign = await firstValueFrom(
      this._discountService.activeDiscountCampaign$,
    );

    const giftOrderCreateParamsCommon: IGiftOrderCreateParamsCommon = {
      type,
      book,
      shipFrom,
      addressBillingData: {
        ...formBillingAddress,
        emailAddress: formEmail.email,
      },
      receiveNewsletters: true,
      paymentInformation: getPaymentInformation(
        [book],
        shipping.id,
        shipping.price,
        formPaymentData?.type || EFormPaymentType.card,
        undefined,
        discountCampaign,
      ),
      referrer: document.referrer,
      hostname: window.location.hostname,
      origin: window.location.origin,
      userAgent: navigator.userAgent,
      userIp: document.documentElement.dataset['userIp'] || '',
      fbp: cookie.parse(document.cookie)['_fbp'] || '',
      fbc: cookie.parse(document.cookie)['_fbc'] || '',
    };

    let giftOrderCreateParams: TGiftOrderCreateParams;

    switch (type) {
      case EGiftOrderType.standart: {
        giftOrderCreateParams = {
          ...giftOrderCreateParamsCommon,
          type: EGiftOrderType.standart, // * typescript issue
        };
        break;
      }
      case EGiftOrderType.special: {
        if (!giftSpecialData) throw getError('!giftSpecialData');

        const {
          password,
          count,
          expiration,
          campaignCode,
          campaignDescription,
          campaignNumber,
          codeType,
          labelTag,
          statusTag,
          extraTag,
        } = giftSpecialData;

        giftOrderCreateParams = {
          ...giftOrderCreateParamsCommon,
          type: EGiftOrderType.special, // * typescript issue
          codeType: Number(codeType),
          password,
          count,
          expiration,
          campaign: {
            code: campaignCode,
            description: campaignDescription,
            number: campaignNumber,
          },
          labelTag,
          statusTag,
          extraTag,
        };
        break;
      }

      // no default
    }

    deleteUndefinedFromObject(giftOrderCreateParams);

    const userGiftOrderDocId = await this._firebaseService.createUserGiftOrder(
      giftOrderCreateParams,
    );

    this._stripeService.currentGiftOrderId = userGiftOrderDocId;

    return new Promise<{
      userGiftOrderId: string;
      giftOrder: TGiftOrderCreated | TGiftOrderGenerated;
    }>((_resolve, _reject) => {
      const sub = new Subscriber();

      const resolve = (...args: Parameters<typeof _resolve>) => {
        sub.unsubscribe();
        _resolve(...args);
      };

      const reject = (...args: Parameters<typeof _reject>) => {
        sub.unsubscribe();
        _reject(...args);
      };

      // handle stripe errors
      sub.push(
        this._firebaseService
          .getStripeGiftPayment$(userGiftOrderDocId)
          .subscribe((payment) => {
            if (!payment) return;

            if ('error' in payment) {
              log.error('paymentUpdate: error', new Date(), payment);
              alert('お支払いにエラーが発生しました。');
              reject(payment.error);
            }
          }),
        this._firebaseService
          .getUserGiftOrder$(userGiftOrderDocId)
          // @ts-ignore
          .pipe(distinctUntilChanged((a, b) => a?.status === b?.status))
          .subscribe(async (giftOrder) => {
            if (!giftOrder) return;

            if (checkGiftOrderError(giftOrder)) {
              reject(giftOrder.error);
              return;
            }

            if (checkCreatedGiftOrder(giftOrder)) {
              log.info('created', { giftOrder });
              const { givenName, familyName, phone } = formBillingAddress;
              const { email } = formEmail;

              if (!formPaymentData) {
                const err = getError('missing required field');
                log.error(err.message);
                return;
              }

              // const { card, name, type: pmType } = formPaymentData;

              switch (formPaymentData.type) {
                case EFormPaymentType.card: {
                  // here we should wait untill the next snapshot will have status 'generated'
                  await this._stripeService
                    .payWithCard(
                      giftOrder.docId,
                      formPaymentData.card,
                      formPaymentData.name,
                      DB_GIFT_PAYMENTS,
                    )
                    .catch((e) => alert(e.message));
                  return;
                  // break;
                }
                case EFormPaymentType.konbini: {
                  // waiting untill konbini action is handled
                  this._stripeService.paymentActionHandle
                    .pipe(take(1))
                    .subscribe(() => {
                      resolve({ userGiftOrderId: giftOrder.docId, giftOrder });
                    });

                  await this._stripeService.payWithKonbini(
                    giftOrder.docId,
                    getFullName(familyName, givenName),
                    email,
                    `${phone.replace(/\D/g, '')}`,
                    DB_GIFT_PAYMENTS,
                  );
                  return;
                  // break;
                }
                default: {
                  reject(
                    new Error(
                      'Invalid payment type! Please reload the page and try again.',
                    ),
                  );
                  return;
                  // break;
                }
              }
            }

            if (checkGeneratedGiftOrder(giftOrder)) {
              log.info('success! generated', { giftOrder });
              resolve({ userGiftOrderId: giftOrder.docId, giftOrder });
            }
          }),
      );
    });
  }
}
